import * as ActionTypes from "../Actions/ActionTypes";

const initialState = {
    sispOpen: false,
    error: null,
    alerts: undefined,
    totalAlerts: undefined,
    viewingAlert: 0,
    dismissingAlerts: [],
    moreLoading: false
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    const currentAlerts = state.alerts === undefined ? [] : state.alerts;
    const newDismissingAlerts = [...state.dismissingAlerts];

    switch (action.type) {
        case ActionTypes.OPEN_ALERTS_SISP:
            return Object.assign({}, state, {
                sispOpen: true
            });
        case ActionTypes.CLOSE_ALERTS_SISP:
            return Object.assign({}, state, {
                sispOpen: false
            });
        case ActionTypes.ALERTS_LOADING:
            return Object.assign({}, state, {
                moreLoading: true
            });
        case ActionTypes.ALERTS_FAILED_TO_LOAD:
            return Object.assign({}, state, {
                error: action.payload.error
            });
        case ActionTypes.ALERTS_LOADED:
            return Object.assign({}, state, {
                alerts: currentAlerts.concat(action.payload.response.alerts.map(function(a) {
                    return {
                        id: a.id,
                        title: a.title,
                        category: a.category,
                        content: a.content,
                        sent: a.sent,
                        formattedSent: a.formatted_sent,
                        month: a.month,
                        isNew: a.is_new,
                        alwaysNew: a.always_new,
                        opened: a.opened
                    }
                })),
                totalAlerts: action.payload.response.total,
                moreLoading: false
            });
        case ActionTypes.VIEW_ALERT:
            return Object.assign({}, state, {
                viewingAlert: action.payload.alertId,
                alerts: currentAlerts.map(function(a) {
                    if (a.id === action.payload.alertId && !a.alwaysNew)a.isNew = false;
                    if (a.id === action.payload.alertId)a.opened = true;
                    return a;
                })
            });
        case ActionTypes.DISMISSING_ALERT:
            newDismissingAlerts.push(action.payload.alertId);
            return Object.assign({}, state, {
                dismissingAlerts: newDismissingAlerts
            });
        case ActionTypes.ALERT_DISMISSED:
            if (state.alerts === undefined) return state;
            const newAlerts = state.alerts.filter(a => a.id !== action.payload.alertId);
            return Object.assign({}, state, {
                alerts: newAlerts,
                totalAlerts: state.totalAlerts - 1,
                dismissingAlerts: newDismissingAlerts.filter(a => a !== action.payload.alertId)
            });
        default:
            return state;
    }
}