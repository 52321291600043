'use strict';

import { Sisp } from '@sprint/sprint-react-components';
import React from 'react';
import { connect } from 'react-redux';
import AlertBanner, { AlertLevel } from '../../CommonComponents/AlertBanner/AlertBanner';
import SispLoading from '../../CommonComponents/Sisp/SispLoading';
import { closeSisp, dismissAlert, loadAlerts, viewAlert } from './Actions/Actions';
import AlertList from './Components/AlertList';

class Alerts extends React.Component {
    componentDidMount() {
        if (this.props.alerts === undefined) this.props.dispatch(loadAlerts('', 0));
    }

    close() {
        this.props.dispatch(closeSisp());
    }

    viewAlert(alertId, markAsOpened) {
        this.props.dispatch(viewAlert(alertId, markAsOpened));
    }

    dismissAlert(alertId) {
        this.props.dispatch(dismissAlert(alertId));
        if (this.props.alerts.length < 10 && this.props.totalAlerts > this.props.alerts.length)
            this.props.dispatch(loadAlerts('', this.props.alerts.length));
    }

    loadMoreAlerts() {
        this.props.dispatch(loadAlerts('', this.props.alerts.length));
    }

    scrollBarAtBottom() {
        if (this.props.moreLoading || this.props.alerts.length === this.props.totalAlerts) return;
        this.loadMoreAlerts();
    }

    render() {
        const showLoader = this.props.alerts === undefined;
        return (
            <Sisp
                isOpen={this.props.sispOpen}
                onCancel={this.close.bind(this)}
                onReachScrollBottom={this.scrollBarAtBottom.bind(this)}
                showSubmitButton={false}
            >
                <h4>Your Alerts</h4>
                {this.props.error ? <AlertBanner alertLevel={AlertLevel.DANGER}>{this.props.error}</AlertBanner> : null}
                {showLoader ? (
                    <SispLoading />
                ) : (
                    <AlertList
                        alerts={this.props.alerts}
                        totalAlerts={this.props.totalAlerts}
                        loadMore={this.loadMoreAlerts.bind(this)}
                        viewAlert={this.viewAlert.bind(this)}
                        viewingAlert={this.props.viewingAlert}
                        dismissingAlerts={this.props.dismissingAlerts}
                        dismissAlert={this.dismissAlert.bind(this)}
                        moreLoading={this.props.moreLoading}
                    />
                )}
            </Sisp>
        );
    }
}

function select(state) {
    return {
        sispOpen: state.main.sispOpen,
        alerts: state.main.alerts,
        totalAlerts: state.main.totalAlerts,
        viewingAlert: state.main.viewingAlert,
        dismissingAlerts: state.main.dismissingAlerts,
        moreLoading: state.main.moreLoading,
        error: state.main.error,
    };
}

export default connect(select)(Alerts);
