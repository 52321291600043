'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from "../../../../CommonComponents/Spinner/Spinner";
import AlertItemHeader from "./AlertItem/AlertItemHeader";
import AlertItemContent from "./AlertItem/AlertItemContent";

/**
 * Close alert and
 */
class AlertItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isExpandable: false
        }
    }

    viewAlert(e) {
        if (!e.target.href) {
            e.preventDefault();
            if (this.props.alert.opened) {
                this.props.viewAlert(this.props.alert.id, true);
            }
            else {
                this.props.viewAlert(this.props.alert.id);
            }
        }
    }

    dismissAlert(e) {
        e.preventDefault();
        this.props.dismissAlert(this.props.alert.id);
    }

    closeAlert(e) {
        if (!e.target.href) {
            e.preventDefault();
            this.props.viewAlert(0);
        }
    }

    viewMoreCalculated(shouldShowViewMore) {
        this.setState({
            isExpandable: shouldShowViewMore
        });
    }

    /**
     * This method is not currently in use as the dismiss functionality is not yet needed.
     *    Left it here because we will need it in future and there is a significant amount of
     *    work I'd have to remove to get rid of it entirely
     */
    dismissButton() {
        if (this.props.dismissingAlert) return <a><Spinner/></a>;
        return <a href="#" onClick={this.dismissAlert.bind(this)}>dismiss</a>;
    }

    render() {
        let alertClasses = 'react-alerts--alert';
        if (this.state.isExpandable) alertClasses += ' react-alerts--alert-expandable';
        return <div className={alertClasses} onClick={this.props.viewingAlert ? this.closeAlert.bind(this) : this.viewAlert.bind(this)}>
            <AlertItemHeader sent={this.props.alert.formattedSent} tooltipDate={this.props.alert.sent} category={this.props.alert.category} isNew={this.props.alert.isNew}/>
            <div className="react-alerts--alert-title">{this.props.alert.title}</div>
            <AlertItemContent content={this.props.alert.content} viewingAlert={this.props.viewingAlert} viewMoreCalculated={this.viewMoreCalculated.bind(this)} />
        </div>;
    }

}

AlertItem.propTypes = {
    alert: PropTypes.object.isRequired,
    viewAlert: PropTypes.func.isRequired,
    dismissAlert: PropTypes.func.isRequired,
    viewingAlert: PropTypes.bool,
    dismissingAlert: PropTypes.bool
};

AlertItem.defaultProps = {
    viewingAlert: false,
    dismissingAlert: false
};

export default AlertItem;
