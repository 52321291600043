'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import AlertItem from "./AlertList/AlertItem";
import AlertPaginator from "./AlertList/AlertPaginator";

class AlertList extends React.Component {

    alerts() {
        const elements = [];
        let currentMonth = '';
        for (let i = 0; i < this.props.alerts.length; i++) {
            const a = this.props.alerts[i];
            if (currentMonth !== a.month) {
                elements.push(<h5 key={i + a.month}>{a.month}</h5>);
                currentMonth = a.month;
            }
            elements.push(<AlertItem
                key={a.id}
                alert={a}
                viewAlert={this.props.viewAlert}
                viewingAlert={this.props.viewingAlert === a.id}
                dismissAlert={this.props.dismissAlert}
                dismissingAlert={this.props.dismissingAlerts.indexOf(a.id) !== -1}
            />)
        }
        return elements;
    }

    render() {
        return <div className="react-alerts">
            {this.alerts()}
            {<AlertPaginator
                currentlyDisplayed={this.props.alerts.length}
                totalAvailable={this.props.totalAlerts}
                loadMore={this.props.loadMore}
                moreLoading={this.props.moreLoading}
            />}
        </div>;
    }

}

AlertList.propTypes = {
    alerts: PropTypes.array.isRequired,
    totalAlerts: PropTypes.number.isRequired,
    loadMore: PropTypes.func.isRequired,
    viewAlert: PropTypes.func.isRequired,
    dismissAlert: PropTypes.func.isRequired,
    dismissingAlerts: PropTypes.array,
    viewingAlert: PropTypes.number,
    moreLoading: PropTypes.bool
};

AlertList.defaultProps = {
    viewingAlert: 0,
    dismissingAlerts: [],
    moreLoading: false
};

export default AlertList;