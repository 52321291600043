'use strict';

import * as ActionTypes from './ActionTypes';
import AlertRepository from "../Alert/AlertRepository";

export function openSisp() {
    return {
        type: ActionTypes.OPEN_ALERTS_SISP
    }
}

export function closeSisp() {
    return {
        type: ActionTypes.CLOSE_ALERTS_SISP
    }
}

export function loadAlerts(filter, offset) {
    return (dispatch) => {
        dispatch(loadingAlerts());
        AlertRepository.search(filter, offset, 10)
            .then(response => dispatch(alertsLoaded(response)))
            .catch(e => dispatch(alertsLoadingFailed(e)));
    }
}

export function viewAlert(alertId, markAsOpened) {
    return (dispatch) => {
        dispatch(alertOpened(alertId));
        if (alertId === 0 || markAsOpened) return;
        AlertRepository.open(alertId)
            .catch(e => dispatch(alertsLoadingFailed(e)));
    }
}

export function dismissAlert(alertId) {
    return (dispatch) => {
        dispatch(dismissingAlert(alertId));
        AlertRepository.dismiss(alertId)
            .then(() => dispatch(alertDismissed(alertId)))
            .catch(e => dispatch(alertsLoadingFailed(e)));
    }
}

function loadingAlerts() {
    return {
        type: ActionTypes.ALERTS_LOADING
    }
}

function alertsLoaded(response) {
    return {
        type: ActionTypes.ALERTS_LOADED,
        payload: {
            response: response
        }
    }
}

function dismissingAlert(alertId) {
    return {
        type: ActionTypes.DISMISSING_ALERT,
        payload: {
            alertId: alertId
        }
    }
}

function alertDismissed(alertId) {
    return {
        type: ActionTypes.ALERT_DISMISSED,
        payload: {
            alertId: alertId
        }
    }
}

function alertOpened(alertId) {
    return {
        type: ActionTypes.VIEW_ALERT,
        payload: {
            alertId: alertId
        }
    }
}

function alertsLoadingFailed(error) {
    return {
        type: ActionTypes.ALERTS_FAILED_TO_LOAD,
        payload: {
            error: error
        }
    }
}
