'use strict';

import HttpRequestService from "../../../Services/HttpRequestService/HttpRequestService";

export default class AlertRepository {

    static search(filter, offset, limit) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('account/alerts/search/?filter=' + encodeURI(filter) + '&offset=' + parseInt(offset) + '&limit=' + parseInt(limit))
                .then(response => {
                    if (response.status !== 200) return reject('Unable to load alerts, endpoint not responding. ERR-REACT-AAR-01');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

    static dismiss(alertId) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('account/alerts/dismiss/' + parseInt(alertId))
                .then(response => {
                    if (response.status !== 200) return reject('Unable to dismiss alert, endpoint not responding. ERR-REACT-AAR-02');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        });
    }

    static open(alertId) {
        return new Promise(function(resolve, reject) {
            new HttpRequestService()
                .get('account/alerts/open/' + parseInt(alertId))
                .then(response => {
                    if (response.status !== 200) return reject('Unable to flag alert, endpoint not responding. ERR-REACT-AAR-03');
                    if (response.data.success === false) return reject(response.data.error);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message);
                })

        });
    }

}